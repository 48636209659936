<template>
    <div>
        <!-- active detail page -->
        <div class="mb-6" style="width: 50%">
            <h4>{{ 'View Detail Outbound Purchase Return ('+this.status+')' }}</h4>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button
                class="ml-4 mt-2"
                color="danger"
                icon-pack="feather"
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <table width="100%" class="vs-table vs-table--tbody-table ">
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    SR No
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ form.sr_code ? this.form.sr_code : "" }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Supplier Code
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ form.supplier_code }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    SR Type
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ Number(this.type) > 0 ? "Without Reference" : "With Reference" }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Supplier Name
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ form.supplier_name }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    GRR Code
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ form.grr_code ? form.grr_code : "-" }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Supplier Address
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ form.supplier_address }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    PO No
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ form.po_code ? form.po_code : "-" }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Notes
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ form.note }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Warehouse
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ form.warehouse ? `${form.warehouse.code} - ${form.warehouse.name}` : "" }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    <!--  -->
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    <!--  -->
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Return Date
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ formatDate(form.date) }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    <!--  -->
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    <!--  -->
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Status
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ this.status }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    <!--  -->
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    <!--  -->
                </td>
            </tr>
        </table>

        <table width="100%" class="vs-table vs-table--tbody-table print">
            <thead class="vs-table--thead">
                <tr>
                    <th>
                        <div>SKU Code</div>
                    </th>
                    <th>
                        <div>SKU Description</div>
                    </th>
                    <th>SR Qty</th>
                    <th>Bad Stock Qty</th>
                    <th>Unit</th>
                    <th>Batch / Exp. Date</th>
                    <th>Return Qty</th>
                    <th>Remaining</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in form.lines" :key="index" style="padding-bottom: 5px">
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.item_unit.sku_code }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.item_unit.item_name }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.quantity }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.quantity }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.item_unit.name }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ `${item.batch_external ? item.batch_external : (item.batch ? item.batch : '-')} / ${item.expired_date ? formatDate(item.expired_date) : "-"}` }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.return_qty }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.quantity - item.return_qty }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {
    },
    mounted() {
        // ini document detail yang digunakan saat ini
        this.id = this.$route.params.id;
        this.return_id = Number(this.$route.query.r);
        this.type = Number(this.$route.query.t);
        this.tabSource = this.$route.params.tabSource;
        this.initData();
    },
    data() {
        return {
            dialog: {
                save: false,
            },
            id: null,
            checkOptions: [{
                value: 0,
                name: "Passed",
            }, {
                value: 1,
                name: "Damaged",
            }],
            attachment: null,
            form: {
                lines: [],
            },
            type: null,
            status: "Loading ...",
            tabSource: null,
            approval: null,
        }
    },
    methods: {
        handleBack() {
            let tabOrder = 0

            switch (this.status) {
                case "Completed":
                    tabOrder = 1;
                    break;
                case "Reversal":
                    tabOrder = 2;
                    break;
                case "Reversed":
                    tabOrder = 3;
                    break;
                default:
                    break;
            }

            if (this.tabSource == "outbound") {
                this.$router.push({
                    name: `${this.$store.state.outbound.supplierReturn.baseRouterName}.index`,
                    params: {order: tabOrder}
                });
            } else {
                this.$router.push({
                    name: "outbound.supplier-return.reversal",
                    params: {order: Number(this.approval)}
                })
            }
        },
        async handlePrint() {
            await window.print();
        },
        initData() {
            this.$http.get(`${this.$store.state.outbound.supplierReturn.baseUrlPath}/${this.id}`, {
                params: {
                    rid: this.return_id,
                    type: this.type,
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    this.form = resp.data;
                    this.approval = resp.data.approval_status;

                    switch (resp.data.status) {
                        case 1:
                            this.status = "Handover"
                            break;
                        case 2:
                            this.status = "Handed-over"
                            break;
                        case 3:
                            this.status = "Completed"
                            break;
                        case 4:
                            this.status = "Reversal"
                            break;
                        case 5:
                            this.status = "Reversed"
                            break;
                        case 6:
                            this.status = "Rejected"
                            break;
                        default:
                            this.status = "Undefined"
                            break;
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
}
</script>

<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
        background: none !important;
    }

    * {
        background: none !important;
    }

    header,
    footer,
    .print-button,
    #content-area * :not(.content-wrapper *) {
        display: none !important;
        visibility: hidden !important;
    }

    .router-view,
    .router-content {
        padding: 0 !important;
        margin: 0 !important;
    }
}

table.print {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

table.print td,
table.print th {
    border: 1px solid #abab !important;
    text-align: left;
    padding: 8px;
}
</style>
